import React, { Component, Fragment } from "react";

const CardAccordion = (props) => {
    return (
        <Fragment>
            <div
                className={
                    "wrapper__accordion " + (props.accordion == props.id ? "active" : "")
                }
            >
                <div
                    className="head pointer"
                    onClick={(e) => props.setAccordionSelect(props.id)}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="semi-bold font__size--20 text__20-1024 mr-2 mb-0">{props.title}</h5>
                        <img src="./../images/arrow-circle-down.png" className="plus" alt="" />
                    </div>
                </div>
                <div className="desc">
                    <p className="normal font__size--16 text__16-1024 color__gray-2 lh-2">
                        {props.desc}
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default CardAccordion;
