import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import CardAccordion from "../../component/Card/CardAccordion";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
const Faq = (props) => {
    const [accordion, setAccordion] = useState();

    const setAccordionSelect = (e) => {
        if (e == accordion) {
            setAccordion(0);
        } else {
            setAccordion(e);
        }
    };

    const [accordionData, setAccordionData] = useState([
        {
            title: "What is Zipcodestack?",
            desc: "It is the best way to get accurate zip code data for your application. This is ",
        },
        {
            title: "How does it work?",
            desc: "Zipcodestack provides a set of REST APIs that are easily implemented in any programming language.",
        },
        {
            title: "Is Zipcodestack entirely free?",
            desc: "Yes, the API is completely free for up to 10 000 requests per month.",
        },
        {
            title: "Do you offer paid plans?",
            desc: "No, paid plans are currently not offered.",
        }
       
    ]);
    return (
        <Fragment>
            <div className="overflow-hidden">

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <h5 className="bold font__size--24 text__24-1024 mb-3">FAQ</h5>

                                {accordionData.map((obj, i) => {
                                    return (
                                        <div className="mb-2">
                                            <CardAccordion
                                                id={i + 1}
                                                title={obj.title}
                                                desc={obj.desc}
                                                accordion={accordion}
                                                setAccordionSelect={(e) => setAccordionSelect(e)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>

            </div >
        </Fragment >
    );
};

export default Faq;
