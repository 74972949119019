import React, { useState, Fragment, useRef, Component } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Faq from "./Faq";
import response from './response.json';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoResponse: JSON.stringify(response, null, 2)
    };

    this.loadDemo = this.loadDemo.bind(this);
  }
  loadDemo() {
    let code = document.querySelector('#code').value;
    fetch('https://app.zipcodestack.com/demo?' + new URLSearchParams({ 'codes': code }))
      .then((response) => response.json())
      .then((data) => {
        this.setState({ demoResponse: JSON.stringify(data, null, 2) });
      });
  }
  render() {
    return <Fragment>
      <div className="overflow-hidden">
        <div className="bg__black">
          <Navbar />
          <section style={{ 'padding-bottom': '80px' }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 text-center text-sm-left">
                  <h1 className="bold font__size--48 text__48-1024 text__48-md text__48-sm color__white mt__5 md-0">Zip Code API - Free
                    Postal Code Validation</h1>
                  <p className="normal font__size--24 text__16-1024 color__white my-4 color__gray-1">
                    The best way to get accurate zip code data for your application.</p>

                  <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
                    <a href="https://app.zipcodestack.com/register" className="wrapper__wrap-mm-100 btn btn__green mb-4 mb-sm-0 font__size--16 text__16-1024 semi-bold color__black shadow rounded__50">Get your API key now</a>
                  </div>
                </div>
                <div className="col-md-5 d-none d-lg-block">
                  <div class="input-group mt__4">
                    <input id="code" style={{ height: 'calc(1.5em + 1rem + 11px)' }} type="text" class="form-control form-control-lg" placeholder="99501"></input>
                    <div>
                      <button onClick={this.loadDemo} id="demo" className="btn btn-primary btn-sm" type="button">Search</button>
                    </div>
                  </div>
                  <div>
                    <SimpleBar style={{ maxHeight: 300 }}>
                      <SyntaxHighlighter id="demoresponse" language="json" style={dracula}>
                        {this.state.demoResponse}
                      </SyntaxHighlighter>
                    </SimpleBar>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="bg__blue">
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="bold font__size--40 text__40-1024 text__40-md text__40-mm color__white">Features</h2>
              <p className="normal font__size--16 text__16-1024 color__gray-1 mb-0">The Zip Code API is a free web service that allows developers to access worldwidezip code data. The API is simple to use and easy to integrate into your applications.</p>
            </div>
            <div className="wrapper__list-archivment d-flex flex-wrap align-items-center justify-content-center">
              <div className="item mb-4 mb-xl-0 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img src="./../images/award.png" alt="" />
                </div>
                <p className="normal color__gray-1 font__size--16 text__16-1024 color__white mb-0 ml-3">Worldwide data</p>
              </div>
              <div className="item mb-4 mb-xl-0 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img src="./../images/award.png" alt="" />
                </div>
                <p className="normal color__gray-1 font__size--16 text__16-1024 color__white mb-0 ml-3">210+ countries</p>
              </div>
              <div className="item mb-4 mb-xl-0 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img src="./../images/close-circle.png" alt="" />
                </div>
                <p className="normal color__gray-1 font__size--16 text__16-1024 color__white mb-0 ml-3">10k requests every month</p>
              </div>
              <div className="item mb-4 mb-xl-0 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img src="./../images/close-circle.png" alt="" />
                </div>
                <p className="normal color__gray-1 font__size--16 text__16-1024 color__white mb-0 ml-3">REST API</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-5 text-center text-lg-left my-lg-auto">
                <div className="wrapper__point justify-content-center justify-content-lg-start mb-3">
                  <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024"><code>GET /search</code></h5>
                </div>
                <h1 className="bold font__size--40 text__40-1024 text__40-md text__40-mm ">Zip code to geolocation</h1>
                <p className="normal font__size--16 text__16-1024  color__gray-2 mb-4">You can get information about the main location for one or multiple postal codes by filtering by country. For example, to find information for the two postal codes 99501 & 60606, you would search worldwide.</p>
                <a href="https://zipcodestack.com/documentation/#endpoints-GETv1-search" className="btn btn__outlined--green font__size--16 text__16-1024 semi-bold color__black h__black no__opacity shadow rounded__50">Documentation</a>
              </div>
              <div className="col-lg-6 text-center text-lg-left my-auto">
                <div className="wrapper__point justify-content-center justify-content-lg-start mb-3">
                  <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024"><code>GET /distance</code></h5>
                </div>
                <h1 className="bold font__size--40 text__40-1024 text__40-md text__40-mm ">Calculate distances between zip codes</h1>
                <p className="normal font__size--16 text__16-1024  color__gray-2 mb-4">This endpoint calculates the distance between one and another (or multiple) postalcodes. Results are available in km or miles. For example, it will return the distance between the US postalcode 99501 and 60606.</p>
                <a href="https://zipcodestack.com/documentation/#endpoints-GETv1-distance" className="btn btn__outlined--green font__size--16 text__16-1024 semi-bold color__black h__black no__opacity shadow rounded__50">Documentation</a>
              </div>
            </div>
          </div>
        </section>
        <Faq />
        <Footer />
      </div>
    </Fragment>
  }
}
export default Index;
