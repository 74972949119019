import React, { Component, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
  const [toogleMenu, setToogleMenu] = useState(false)

  const status = localStorage.getItem("status") == "login" ? true : false


  return (
    <Fragment>
      <div className={"wrapper__side-nav-mobile d-flex d-lg-none " + (toogleMenu ? "active" : "")}>
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center mb-4">
              <img src="./../images/close (1).png" onClick={() => setToogleMenu(!toogleMenu)} className="pointer" alt="" />
              <NavLink
                to="/"
                exact
                class={"navbar-brand font__size--20 text__20-1024 bold " + (props.type == "light" ? "color__black" : "color__white")}
              >
                <div className="d-flex align-items-center">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M36.6168 20C36.6168 25.5167 32.1334 30 26.6168 30C25.2128 30 23.8692 29.7104 22.6578 29.1825C22.3452 29.0464 22.2864 28.6388 22.5179 28.3885C24.6143 26.1217 25.7834 23.133 25.7834 20C25.7834 16.8547 24.6023 13.8533 22.5212 11.6121C22.2886 11.3617 22.347 10.9528 22.6603 10.8164C23.8711 10.2892 25.2138 10 26.6168 10C32.1334 10 36.6168 14.4833 36.6168 20Z" fill={props.type == "light" ? "#064BB5" : "white"} />
                    <path d="M23.2834 20C23.2834 22.95 22.0001 25.6167 19.9501 27.4333C18.1834 29.0333 15.8501 30 13.2834 30C7.76678 30 3.28345 25.5167 3.28345 20C3.28345 14.4833 7.76678 10 13.2834 10C15.8501 10 18.1834 10.9667 19.9501 12.5667C22.0001 14.3833 23.2834 17.05 23.2834 20Z" fill={props.type == "light" ? "#064BB5" : "white"} />
                  </svg>

                  <span className="ml-2">Zipcodestack</span>
                </div>
              </NavLink>
            </div>
            <div className="menu">
              <NavLink to="/" className="semi-bold font__size--16 text__16-1024 color__white">API</NavLink>
              <hr />
              <a href="/documentation" class="semi-bold font__size--16 text__16-1024 color__white">Documentation</a>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="bg__wrap-menu d-lg-none" onClick={() => setToogleMenu(!toogleMenu)}></div>
      <nav
        className="navbar navbar-expand-lg wrapper__navbar position-relative z-2"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <div className="d-flex align-items-center">
            <div
              onClick={() => setToogleMenu(!toogleMenu)}
              class="nav__button d-lg-none position-relative mr-1 mr-sm-3 flex-shrink-0"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <NavLink
              to="/"
              exact
              class={"navbar-brand font__size--20 text__20-1024 bold " + (props.type == "light" ? "color__black" : "color__white")}
            >
              <div className="d-flex align-items-center">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M36.6168 20C36.6168 25.5167 32.1334 30 26.6168 30C25.2128 30 23.8692 29.7104 22.6578 29.1825C22.3452 29.0464 22.2864 28.6388 22.5179 28.3885C24.6143 26.1217 25.7834 23.133 25.7834 20C25.7834 16.8547 24.6023 13.8533 22.5212 11.6121C22.2886 11.3617 22.347 10.9528 22.6603 10.8164C23.8711 10.2892 25.2138 10 26.6168 10C32.1334 10 36.6168 14.4833 36.6168 20Z" fill={props.type == "light" ? "#064BB5" : "white"} />
                  <path d="M23.2834 20C23.2834 22.95 22.0001 25.6167 19.9501 27.4333C18.1834 29.0333 15.8501 30 13.2834 30C7.76678 30 3.28345 25.5167 3.28345 20C3.28345 14.4833 7.76678 10 13.2834 10C15.8501 10 18.1834 10.9667 19.9501 12.5667C22.0001 14.3833 23.2834 17.05 23.2834 20Z" fill={props.type == "light" ? "#064BB5" : "white"} />
                </svg>

                <span className="ml-2">Zipcodestack</span>
              </div>
            </NavLink>
          </div>
          <div className="d-flex align-items-start">
            <a href="/documentation" class="semi-bold font__size--16 text__16-1024">Documentation</a>
          </div>

          <div class="d-lg-none">
            <a class="semi-bold font__size--16 text__16-1024 color__black btn btn__green shadow h__black rounded__50" href="https://app.zipcodestack.com/login">Login</a>
          </div>

          <div class="">

            <a class="d-none d-lg-block semi-bold font__size--16 text__16-1024 color__black btn btn__green shadow h__black rounded__50" href="https://app.zipcodestack.com/login">Login</a>
          </div>


        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
