import React, { Component, Fragment } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Footer = (props) => {

    return (
        <Fragment>
            <section className="pb-4">
                <div className="container">
                    <div className="row mb-5">
                        <div className="mb-4 mb-lg-0 text-center text-md-left col-md-6 col-lg-4">
                            <div
                                class="navbar-brand font__size--20 text__20-1024 bold color__black"
                            >
                                <div className="d-flex align-items-center">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M36.6168 20C36.6168 25.5167 32.1334 30 26.6168 30C25.2128 30 23.8692 29.7104 22.6578 29.1825C22.3452 29.0464 22.2864 28.6388 22.5179 28.3885C24.6143 26.1217 25.7834 23.133 25.7834 20C25.7834 16.8547 24.6023 13.8533 22.5212 11.6121C22.2886 11.3617 22.347 10.9528 22.6603 10.8164C23.8711 10.2892 25.2138 10 26.6168 10C32.1334 10 36.6168 14.4833 36.6168 20Z" fill="#064BB5" />
                                        <path d="M23.2834 20C23.2834 22.95 22.0001 25.6167 19.9501 27.4333C18.1834 29.0333 15.8501 30 13.2834 30C7.76678 30 3.28345 25.5167 3.28345 20C3.28345 14.4833 7.76678 10 13.2834 10C15.8501 10 18.1834 10.9667 19.9501 12.5667C22.0001 14.3833 23.2834 17.05 23.2834 20Z" fill="#064BB5" />
                                    </svg>

                                    <span className="ml-2">Zipcodestack</span>
                                </div>
                            </div>
                            <p className="normal font__size--16 text__16-1024 color__gray-2">The best way to get accurate zip code data for your application.</p>
                        </div>
                        <div className="mb-4 mb-lg-0 text-center text-md-left col-md-3 col-lg-2">
                            <ul className="bold font__size--16 text__16-1024 wrapper__list-menu">
                                <li><a href="/" className="semi-bold color__gray-2">API</a></li>
                                <li><a href="/documentation" className="semi-bold color__gray-2">Documentation</a></li>
                                <li><a href="/privacy-policy" className="semi-bold color__gray-2">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <p className="mb-0 normal font__size--16 text__16-1024 color__gray-2 text-center">© Copyright 2022</p>
                </div>
            </section>
        </Fragment>
    );
};

export default Footer;
